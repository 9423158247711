import React from 'react'
import get from 'lodash/get'
import { graphql } from 'gatsby'

import ContentfulPage from '../components/Page/ContentfulPage'
import withContentfulBlocks from '../components/withContentfulBlocks'
import { MEMBERSHIPS } from '../components/SL/MembershipSelectorCard/MembershipSelectorCard'

class OurServicesIndex extends React.Component {
  render () {
    const membershipCards = get(this.props, 'data.membershipCards.edges')
    const membershipHeaders = get(this.props, 'data.membershipHeader.nodes')
    const membershipHeader = membershipHeaders.length ? membershipHeaders[0].membershipHeader : ''

    const basicFeatures = get(this.props, 'data.basicFeatures.edges')[0].node.features
    const classicFeatures = get(this.props, 'data.classicFeatures.edges')[0].node.features

    membershipCards.map(card => {
      if (card.node.type === MEMBERSHIPS.BASIC) {
        card.node.features = basicFeatures
      }

      if (card.node.type === MEMBERSHIPS.CLASSIC) {
        card.node.features = classicFeatures
      }

      return card
    })

    return (
      <ContentfulPage
        blocks={this.props.blocks}
        footerColor='white'
        siteName={get(this.props, 'data.site.siteMetadata.siteName')}
        pageTitle={`${get(this.props, 'data.site.siteMetadata.siteName')} / Our services`}
        pageUrl={`${get(this.props, 'data.site.siteMetadata.url')}/our-services`}
        membershipCards={membershipCards}
        membershipHeader={membershipHeader}
      />
    )
  }
}

export default withContentfulBlocks(OurServicesIndex)

export const pageQuery = graphql`
query SLOurServicesQuery {
  site {
    ...SiteMetaDataFragment
  }
  page: contentfulPage(contentful_id: { eq: "2KJnEqe6nvMAxDkxRhE6gT" }) {
    ...PageBlocksFragment
  }
  membershipCards: allContentfulShelfLifeMembershipCard {
    edges {
      node {
        id
        type
        planDisplayName
        subText
      }
    }
  }
  basicFeatures: allContentfulPanelFeaturesList(
    filter: { membershipType: { eq: "basic" } }
  ) {
    edges {
      node {
        id
        features
      }
    }
  }
  classicFeatures: allContentfulPanelFeaturesList(
    filter: { membershipType: { eq: "classic" } }
  ) {
    edges {
      node {
        id
        features
      }
    }
  }
  membershipHeader: allContentfulShelfLifeMembershipHeader {
    nodes {
      membershipHeader
    }
  }
}
`
